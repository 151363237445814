@import '/src/sass/variables';
@import '@fgb/portal-component-library/styles';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';
/* ELEMENT STYLING */

html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    @if $light-theme {
      background: transparent;
      color: black;
    } @else {
      background: #000;
      color: white;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  text-transform: uppercase !important;
  font-family: $font-heading;
  font-size: 25px !important;
  -webkit-font-smoothing: antialiased;
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

/* Form Colors */

.form-control {
  color: #000000;
}

/* Banner */

.banner {
  // background: url('../src/assets/images/banner-fortress.jpg');
  background-size: 100% 100px !important;
  justify-content: center;

  @media all and (max-width: 991px) {
    background-size: 100% 70px !important;
  }

  h1 {
    color: $primary-color;
    font-size: 22px;
  }
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner.jpg);
  background-size: cover;
  background-position: center;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
}

.nav.nav-secondary {
  margin-bottom: 0px;
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
      width: 5%;
      @media only screen and (min-width: 992px) {
        width: auto;
      }
    }
    background-color: transparent;
  }
}

.nav.nav-secondary .nav-item {
  @media only screen and (max-width: 991px) {
    flex: 1 1;
  }
}
fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid $error-color;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.filter-modal-dialog {
  display: flex;
  align-items: flex-end !important;
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: $black;
  padding: 0 10px 15px 15px;
}

/** Scorecards */

.bg-carousel {
  background-color: transparent !important;
}

.scorecard-carousel-links {
  .badge-carousel-subheading {
    font-size: 1.25rem !important;
    font-family: $font-heading;
  }

  .scorecard-featured-link {
    font-size: 0.75rem !important;
    font-family: $font-default;
  }
}

.badge-carousel-subheading,
.scorecard-featured-rewards-link {
  color: $black;
}

.ps-1.scorecard-featured-rewards-link {
  font-weight: bold !important;
  margin-left: 5px;
}

.scorecard-name,
.one-punchcard-name,
.scorecard-locked-name {
  font-size: 0.875rem !important;
  text-transform: capitalize !important;
}

.scorecard-punchcard-steps {
  font-size: 12px !important;
}

.scorecard-description-prize .prize-label,
.scorecard-prize-label {
  text-transform: uppercase !important;
}

.scorecard-description-carousel-header {
  margin-bottom: 40px;
  @media (max-width: 991px) {
    margin-bottom: 0px;
  }

  .previous-btn {
    left: 25px;
  }

  .next-btn {
    right: 25px;
  }
}

.scorecard-description-congratulation-title {
  color: $secondary-color !important;
}

.scorecard-description-prizebox {
  background-color: $secondary-color !important;
}

.scorecard-description-share {
  .share-icon {
    background-color: $black !important;
  }
}

.sponsor-img-text {
  .sponsor-label {
    font-size: 12px !important;
  }

  .sponsor-img {
    height: 40px !important;
    width: 80px !important;
    object-fit: contain;
  }
}

.scorecard-prize-border {
  .sponsor-img {
    height: 23px !important;
    width: 46px !important;
    object-fit: contain;
  }
}

.scorecard-description-carousel-header .scorecard-description-back-btn {
  top: 20px;
  left: 15px;
}

fgbcl-scorecard-punchcard-view {
  position: relative;
  z-index: 1;
}

fgbcl-scorecard-carousel {
  .badge-carousel-div {
    padding-bottom: 0px !important;
    padding-top: 16px !important;
  }
}

@media (max-width: 991px) {
  .scorecard-linked-shadow {
    margin-top: 0px;
  }
  .scorecard-description-page {
    background-color: white;
    position: absolute;
    height: 100vh;
    width: 100%;

    & > .container {
      height: calc(100vh - 215px);
      overflow: auto;
    }
  }
}

.scorecard-modal-title {
  font-family: $font-heading;
  font-size: 18px;
  text-transform: uppercase;
}

.scorecard-description-content {
  .scorecard-description-name {
    margin-top: 20px;
  }

  .prize-content {
    margin-bottom: 20px;
    color: $secondary-color;
  }
}

.scorecard-description-modal {
  .scorecard-description-modal-action-completed,
  .scorecard-actions-completed {
    font-size: 12px;
  }
  .scorecard-actions-completed {
    background-color: $secondary-color;
  }
  .scorecard-description-modal-prizebox {
    border-radius: 4px;
  }
  .scorecard-modal-banner .scorecard-modal-image img {
    height: 70px;
  }
}

.scorecard-description-desc-terms {
  .accordion-button:not(.collapsed) {
    color: $black;
  }
}

.scorecard-punchcard-footer-container,
.scorecard-milestones-footer-container {
  border: 1px solid $secondary-color;
}

.scorecard-description-modal-carousel {
  background-color: $primary-color;
  .next,
  .previous {
    color: $secondary-color;
  }
  .steps {
    .current-step,
    .total-step {
      color: $secondary-color;
    }
    .dash {
      background: $secondary-color;
    }
  }
}

.scorecard-Milestone-Milestones-Value-model-v2,
.punchcard-info-btn,
.milestone-info-btn,
.scorecard-milestone-value {
  color: $secondary-color;
}

.progress.milestone-progress-bar {
  .progress-bar {
    background-color: $primary-color !important;
  }
}

.scorecard-one-punch-complete-name {
  color: $black !important;
}

.badge-subheading {
  color: $black;
  margin-top: 0 !important;
}

.counter-number-scorecard {
  font-size: 40px !important;
}

.scorecard-description-modal .scorecard-description-modal-dash-line-top {
  color: $primary-color;
  font-size: 40px !important;
}

.badge-carousel-div {
  padding-top: 0 !important;
}

.scorecard-description-modal .scorecard-description-modal-congratulation-title {
  font-size: 18px;
  text-transform: uppercase;
}

.scorecard-description-linked-action,
.scorecard-description-actions {
  padding: 0px 10px;
}

.scorecard-item-terms-url {
  color: $black;
}

/**Badge Achieved*/

fgbcl-badge-achieved {
  .badge-achieved .offcanvas-header {
    background-color: $secondary-color !important;
    color: $white !important;
  }
  .badge-achieved .all-badges-achieved-offcanvas .offcanvas-header .badge-achieved-title,
  .badge-achieved .all-badges-achieved-offcanvas .offcanvas-header .icon-close {
    color: $white !important;
  }
  .badge-achieved {
    background-color: $secondary-color;
    .badge-achieved-container {
      height: 80px;
      background-color: $secondary-color;
      @media only screen and (max-width: 991px) {
        height: 129px;
        padding: 15px;
      }

      .badge-achieved-title {
        font-size: 20px !important;
        font-family: $font-heading;
      }

      .badge-image {
        margin-bottom: 12px !important;
        @media only screen and (max-width: 991px) {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.badge-achieved {
  .all-badges-achieved-offcanvas {
    @media only screen and (max-width: 991px) {
      height: 90% !important;
    }

    .offcanvas-header {
      .badge-achieved-title {
        font-size: 20px !important;
        font-family: $font-heading;
        color: $black;
      }

      .badge-achieved-title,
      .icon-close {
        color: $black !important;
      }
    }

    .offcanvas-body {
      .badge-subheading {
        font-size: 20px !important;
        font-family: $font-heading;
      }
    }
  }
}

/** Offers */

fgbcl-campaigns-offers-list {
  .offer-items-header-link-icon {
    margin-left: 10px;
    font-weight: bold !important;
  }
}

.campaigns-offers-list .offer-items-header .offer-items-header-link,
.scorecard-featured-rewards-link {
  font-weight: normal;
}

fgbcl-campaigns-offers-list {
  .campaign-list-item-card {
    border: 1px solid #dee2e6 !important;
    box-shadow: none !important;
  }
}

.nav.nav-secondary .nav-item.active {
  @media (min-width: 991px) {
    border-bottom: none;
  }
}

.nav.nav-secondary .nav-item {
  .icon,
  label {
    color: $grey-color;
  }
}

.owl-carousel .owl-stage-outer {
  padding-left: 0 !important;
}

.owl-theme .owl-dots .owl-dot.active span {
  background: $secondary-color;
}

/** Announcements */

.announcements-text .announcements-title {
  font-family: $font-default !important;
  font-size: 0.875rem !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

.announcements-carousel {
  .announcements-main-title {
    font-size: 1.25rem !important;
    font-family: $font-heading;
    margin-top: 20px !important;
  }
}

.announcement-link {
  color: $secondary-color;
  font-weight: normal;
}

fgb-footer {
  p {
    margin-bottom: 0 !important;
  }
}

/** Registration */

.registration-personal-details {
  h5 {
    font-size: 25px;
    color: $black;
    @media all and (max-width: 991px) {
      text-align: left;
    }
  }
  .logo {
    display: none;
  }
}

.registration-terms-and-condition h5 {
  font-size: 25px;
  color: $black;
  text-align: left;
}

.terms-section-box {
  border: none;
}

/** Rewards */

fgbcl-lotto-details {
  .accordion-button,
  .accordion-item {
    background-color: $grey-color;
  }

  .loyalty-share {
    display: none !important;
  }
  .form-check,
  .rewards-button-background,
  .lotto-details-countdown,
  .marketplace-details-points,
  .reward-status,
  .status-container {
    display: none !important;
  }
}
fgbcl-lottos-list {
  .loyalty-item-container {
    margin-top: 10px !important;
  }
  .no-items.no-results-placeholder-div {
    margin: 0px auto !important;
    background-color: $white !important;
    border: 1px solid $disabled-color !important;
  }
}
fgbcl-lottos-list-item {
  .rewards-item-panel-value,
  .status-text-on-not-enough-points {
    display: none !important;
  }
  .loyalty img {
    object-fit: inherit !important;
    @media (min-width: 992px) {
      max-height: 298px !important;
    }
  }
  fgbcl-countdown {
    .countdown-timer {
      display: none !important;
    }
  }
}

.wallet-unique-code-item {
  color: $secondary-color;
}

.marketplace-wallet-download-btn {
  color: $secondary-color !important;
  .font-size-14 {
    font-size: 14px !important;
    font-weight: bold;
  }
}

.wallet-description-divider {
  display: none !important;
}

.wallet-back-button {
  color: #d22730;
}

.no-wallet-items {
  font-family: $font-heading;
  font-size: 20px !important;
}

/** FAQs */

.faq-container .card .card-header {
  background-color: $secondary-color;
}

.faq-question-titles {
  color: $white;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.faq-container .card {
  box-shadow: none !important;
  border: 1px solid #f1f1f2 !important;
}

.faq-container .faq-section-title {
  font-size: 1.25rem !important;
  font-family: $font-heading;
}
.faq-container .faq-section-card-title {
  &.accordion-button {
    align-items: start !important;
    gap: 10px;
  }

  &.accordion-button:not(.collapsed) {
    color: $white;
    background-color: $secondary-color;
  }
}

.faq-section-card-title {
  font-weight: bold;
}

.faq-section-card-title,
.faq-section-card-description {
  font-family: $font-default !important;
  font-size: 0.875rem !important;
}

/** Terms & Conditions*/

.terms-section-content {
  h6 {
    font-weight: bold;
  }

  h6,
  p {
    font-size: 0.875rem;
    font-family: $font-default;
  }
}

@import 'bootstrap/scss/bootstrap';

@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }
}

/** Wallet */
fgbcl-rewards-wallet-redeem-list {
  .rewards-wallet-redeem-title {
    font-size: 20px !important;
  }
  .action-wrapper {
    button {
      color: $black !important;
    }
  }
}

fgbcl-marketplace-wallet-details,
fgbcl-lottos-wallet-details {
  .enter-code-text {
    color: black;
  }
  .marketplace-wallet-download-btn {
    background-color: $primary-color !important;
  }
  .wallet-item-title,
  .marketplace-claim-text {
    color: $black;
  }
  .wallet-unique-code-item {
    color: $secondary-color;
  }
  .container:first-of-type {
    padding-top: 20px;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  }
  .accordion-button {
    color: $black !important;
  }
  .item-details-title {
    font-family: $font-heading;
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .wallet-points-value {
    display: none !important;
  }
}

fgbcl-rewards-wallet-redeemed-list {
  .wallet-marketplace-item-name {
    font-size: 12px;
  }
  .wallet-container {
    background-color: $white;
  }
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: $black !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
}

fgbcl-registration-terms-and-conditions {
  h5 {
    font-size: 1.25rem !important;
    font-family: $font-heading !important;
    text-transform: uppercase !important;
  }

  .registration-terms-and-condition .content {
    @media screen and (min-width: 991px) {
      max-height: calc(100vh - 400px) !important;
    }
  }

  .terms-section-content p {
    font-size: 0.75rem;
  }

  .form-check-input:checked {
    background-color: $success-color !important;
    border-color: $success-color !important;
  }

  .terms-section-box {
    padding: 0px !important;
  }

  .wrap-accept-box {
    .form-check {
      font-size: 0.875rem !important;
    }
  }
}

fgbcl-registration-navigation {
  .btn-previous-step,
  .btn-previous-next {
    font-size: 0.875rem !important;
  }
  .btn-previous-step {
    border: none !important;
  }
}

.scorecard-description-share {
  display: none;
}

.short-long > .btn-toggle {
  color: $black;
}

/** Payment */

fgbcl-country-and-regions {
  .invalid-feedback {
    font-weight: normal !important;
  }
}

@media (min-width: 991px) {
  .btn.add-card {
    width: 142px !important;
    height: 120px;
  }
  .btn.add-card .icon {
    font-size: 24px !important;
    margin-right: 0px !important;
  }
}

.bg-add-card {
  .icon,
  label {
    color: $black !important;
  }
}

.bg-add-card {
  @media (min-width: 991px) {
    width: 142px !important;
  }
}

.ecash-description-box {
  background-color: $white;
}

.add-card-label {
  text-transform: none !important;
}

.payment-cards-title {
  font-size: 20px !important;
}

fgbcl-ecash-description {
  h5 {
    font-size: 20px !important;
  }

  .ecash-description-box .vertical-line {
    border-top-color: #dedddd;
    opacity: 1;
  }

  .ecash-description-box .ecash-description-icon .color-3 {
    color: $black;
  }
}

.active-card {
  background-color: $black !important;
}

fgbcl-confirm-modal {
  h5 {
    font-family: $font-default;
    font-size: 0.875rem !important;
    text-transform: capitalize !important;
  }
}

fgbcl-card-token {
  .btn.delete {
    margin-bottom: 0 !important;
  }
  .payment-card .btn-status.activate {
    border: none !important;
  }
  .card-dropdown {
    border-radius: 16px !important;
    height: auto !important;
    width: auto !important;
    min-width: unset;
    padding: 7px 20px 4px 20px !important;
  }
  .card-status.freeze-color,
  .unfreeze,
  .payment-card .card-status,
  .btn.delete {
    padding-left: 0;
    padding-right: 0;
  }
  .text-success {
    .font-size-14.text-capitalize {
      min-width: 86px;
    }
  }
  .activate.text-success {
    color: $success-color !important;
    .font-size-14.text-capitalize {
      min-width: 86px;
    }
  }
  .payment-card.active-card,
  .payment-card.inactive-card,
  .payment-card.frozen-card {
    border-radius: 16px !important;
  }
  .payment-card {
    .font-size-14 {
      font-size: 12px !important;
    }
    .font-size-18 {
      font-size: 16px !important;
    }
  }
}

.ytp-cued-thumbnail-overlay-image {
  background-size: contain !important;
}
fgbcl-top-up-address {
  .form-floating > label {
    font-weight: 400 !important;
  }
}
.announcements-carousel .announcements-main-title {
  margin-top: 15px !important;
}
fgbcl-scorecard-list-view {
  .no-items.no-results-placeholder-div {
    border: 1px solid $disabled-color;
    background-color: $white !important;
    color: $black;
  }
}
fgbcl-scorecard-description-header {
  @media all and (max-width: 991px) {
    .scorecard-description-content .scorecard-description-name {
      margin-top: 0px !important;
    }
    .scorecard-description-content .prize-content {
      margin-bottom: 0px !important;
    }
  }
}
fgbcl-scorecard-description-actions {
  .scorecard-description-actions .scorecard-actions-completed {
    @media all and (max-width: 991px) {
      margin: 30px 0px 15px 0px !important;
    }
  }
  .scorecard-description-actions .action-incompleted {
    filter: none !important;
  }
}

.country-and-regions .form-floating .form-select {
  min-height: 60px !important;
}
.nav.nav-secondary .nav-item:active,
.nav.nav-secondary .nav-item:hover {
  border-bottom: none !important;
}

// Fix Scroll issue
:has(.offcanvas.show) {
  body {
    overflow: hidden !important;
  }
}

body {
  overflow: unset !important;
}
